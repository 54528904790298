import React from "react"
import Layout from "../common/layout/layout"
import SEO from "../common/seo/seo"

class Agency extends React.Component {
  componentDidMount = () => {
    window.location.href='/apply'
  }

  render() {
    return (
      <Layout>
        <SEO title="Agency" />
      </Layout>
    )
  }
}

export default Agency
